import React, {useEffect} from "react";
import useSelector from "../../common/hooks/useSelector";
import Notification from "./Notification";
import {useParams} from "react-router-dom";
import fetchNotifications from "../../users/actions/fetchNotifications";
import useDispatch from "../../common/hooks/useDispatch";


export default function Notifications() {
    const {locale} = useParams<{ locale: string }>();
    const notifications = useSelector(state => state.users.notifications[locale]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof notifications === "undefined") {
            dispatch(fetchNotifications(locale));
        }
    }, [locale, dispatch, notifications])

    return (
        <div>
            {notifications?.map(notification => (
                <Notification key={notification.notificationId} {...notification} />
            ))}
        </div>
    )
}